import { Component,Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { OfferteService } from '../services/offerte.service';
import { CategoryService } from '../services/category.service';
import { debug } from 'util';
 
const STORAGE_KEY = 'notifications';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public storage: Storage, public offerteService: OfferteService, public categoryService: CategoryService) {


  }

  notificationsList = []
  
  isNotificationActive(categoryId) {
    return this.getAllNotifications().then(result => {
      if(result){
        for(var i = 0; i< result.length;  i++){
          if(result[i].id == categoryId)
            return true;
        }
      }
    });
  }
 
  notificationsOn(categoryId) {

    if((<any>window).FirebasePlugin)
      (<any>window).FirebasePlugin.subscribe(categoryId);
    else{
      
      this.offerteService.subscription(true, categoryId).subscribe(() =>{
        debugger
      });
    }

    return this.getAllNotifications().then(result => {

      this.offerteService.getSingleCategory(categoryId).subscribe(async item =>{
        if (!result) {
          result = []
        }
        result.push(item[0]);
        
        this.notificationsList = result;

        for(var i = 0; i < this.notificationsList.length; i++){
          this.categoryService.getCategoryImage(this.notificationsList[i]);
        }

        return this.storage.set(STORAGE_KEY, result);
        
      })


    });
  }
 
  notificationsOff(categoryId) {

    if((<any>window).FirebasePlugin)
      (<any>window).FirebasePlugin.unsubscribe(categoryId);
    else{
      this.offerteService.subscription(false, categoryId).subscribe(() =>{
        debugger
      });
    }

    return this.getAllNotifications().then(result => {
      this.offerteService.getSingleCategory(categoryId).subscribe(async item =>{
        if (result) {
          var index = -1;
          for(var j = 0; j< result.length; j++){
            if(result[j].id == categoryId){
              index = j;
              break;
            }
          }
          result.splice(index, 1);

          this.notificationsList = result;

          for(var i = 0; i < this.notificationsList.length; i++){
            this.categoryService.getCategoryImage(this.notificationsList[i]);
          }

          return this.storage.set(STORAGE_KEY, result);
        }
      });
    });
  }
 
  getAllNotifications() {
    return this.storage.get(STORAGE_KEY);
  }

}
